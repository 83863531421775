<template>
    <div>
        <div class="comment_item">
            <div class="more" v-if="item.user_id==USER_ID">
                <!-- <i class="el-icon-more"></i> -->
                <template>
                <el-popconfirm :title="$t('confirmDelete')+'?'" :cancel-button-text="$t('cancel')"  :confirm-button-text="$t('delete')" @onConfirm="del" @cancel="cancel">
                    <el-button class="btn_more" type='text'  slot="reference" >{{$t('delete')}}</el-button>
                </el-popconfirm>
                </template>
            </div>
            <img :src="item.user_avatar?item.user_avatar:DEFAULT_AVATAR"  alt="">
            <div class="content">
                <div class="line_1">
                    <h1 class="textOverflow" style="max-width:150px" :title="item.user_name_en|priorFormat(item.user_name_zh,LOCALE)">{{item.user_name_en|priorFormat(item.user_name_zh,LOCALE)}}</h1>
                </div>
                <div class="msg">{{item.content}}</div>
                <div class="bpttom">
                    <span class="txt">{{ item.created_time| secondFormat('LLL') }}</span>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
export default {
    props:['item'],
    data(){
        return{
        }
    },
    mounted(){

    },
    methods:{
        async del(){
            let params={
                deleted:1,
                video_comment_id:this.item.id
            }
            let del = await this.$store.dispatch("API_position/DEL_video_comment",params); 
            if(del && del.success){
                this.$message.success(this.$t('delSuccess'))
                this.$emit("del",'success')
            }
        },
        cancel(){

        }

    }
}
</script>

<style scoped lang="less">
    /deep/ .el-popconfirm__main {
        margin-bottom: 10px;
    }
    .comment_item{
        margin-top: 20px;
        position: relative;
        display: flex;
        .more{
            position: absolute;
            right:5px;
            bottom:20px;
            cursor: pointer;
            .btn_more{
                display: inline-block;
            }
        }
        img{
            width: 48px;
            height: 48px;
            border-radius:50%;
        }
        .content{
            margin-left:16px;
            padding-bottom: 20px;
            width: 100%;
            border-bottom: 1px solid #DCDFE6;
            .line_1{
                margin-top:4px;
                height: 20px;
                display: flex;
                align-items: center;
                h1{
                    height: 20px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 20px;
                    padding-right: 6px;
                }
                span{
                    height: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 20px;
                }
            }
        }
        .msg{
            padding-top:13px;
            padding-bottom: 16px;
            font-size: 12px;
            font-weight: 400;
            color: #3C5058;
            line-height: 17px;
        }
        .bpttom{
            display: flex;
            align-items: center;
            .txt{
                height: 17px;
                font-size: 12px;
                font-weight: 400;
                color: #939393;
                line-height: 17px;
            }
            .zan{
                font-weight:bold;
                margin-left: 16px;
                margin-right: 7px;
                cursor: pointer;
            }
        }
    }
</style>