<template>
    <div class="detail">
        <div class="topBox">
            <div class="player_msg">
                <div class="player">
                    <AliyunVideo
                        ref="AliyunVideo"
                        class="liveThumb"
                        :AliyunId="item.aliyun_id"
                    ></AliyunVideo>
                </div>
                <div class="msg">
                    <comment></comment>
                </div>
            </div>
            <div class="desc">
                <div class="icon iconfont iconshoucang scPos" @click="handleSC"  :class="{isActive:isSoucang}" ></div>
                <div class="topic" style="margin-bottom:24px">
                    <span class="tit">课程题目</span>
                    <span class="txt textOverflow" style="width:230px;" >{{ item.name|textFormat }}</span>
                </div>
                <div>
                    <span class="tit">课程简介</span>
                    <div class="jianjie">{{ item.desc|textFormat }}</div>
                </div>
                <div>
                    <span class="tit">讲师</span>
                    <div class="jianjie">{{item.lecturer|textFormat }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AliyunVideo from "~/baseComponents/AliyunVideo";
import comment from "~hbs/components/college/comment";
import "@/baseAssets/icon/iconfont.css";
export default {
    components:{
        comment,AliyunVideo
    },
    metaInfo: {
        title: "物贸学院",
    },
    data(){
        return{
            video_id:"",
            item:{},
            isSoucang:false
        }
    },
    async mounted(){
        // 初始化 视频播放次数+1
        this.video_id=this._decode(this.$route.query.parameter).video_id
        await this.$store.dispatch('API_company/play_count_Add',this._decode(this.$route.query.parameter));
        // 获取视频信息
        this.getVideo()
        // 检查是否已经收藏了视频
        this.cheechSC()
    },
    methods:{
        async getVideo(){
            let params={
                source:this.PJSource,
                video_id:this.video_id
            }
            let res=await this.$store.dispatch('API_company/association_video_List', params)
            this.item=res.data[0]
        },
        async cheechSC(){
            if(!this.USER_INFO.id){
                this.isSoucang=false
                return
            }
            let params={
                user_id:this.USER_INFO.id,
                video_id:this.video_id
            }
            let res=await this.$store.dispatch('API_company/check_video_SC', params)
            if(res.is_like==0){
                this.isSoucang=false
            }else{
                this.isSoucang=true
            }
        },
        async handleSC(){
            let params={
                user_id:this.USER_INFO.id,
                video_id:this.video_id,
                like_type:this.isSoucang?0:1
            }
            let res=await this.$store.dispatch('API_company/association_video_Like', params)
            this.isSoucang=!this.isSoucang
            if(this.isSoucang){
                this.$message.success('已收藏')
            }else{
                this.$message.info('取消收藏')
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .detail{
        width: 1200px;
        margin: auto;
        padding-top:24px;
        padding-bottom: 60px;
        .topBox{
            display: flex;
            justify-content: space-between;
            .player_msg{
                .player{
                    width: 804px;
                    height: 464px;
                    background: #1F292E;
                    .liveThumb {
                        width: 100%;
                        height: 464px;
                        margin-bottom: 10px;
                        position: relative;
                        .enterBtn {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        }
                    }
                }
                .msg{
                    margin-top: 24px;
                    width: 804px;
                    display: flex;
                    min-height: 343px;
                    background: #FFFFFF;
                }
            }
            .desc{
                padding:16px;
                width: 384px;
                height: fit-content;
                background: #FFFFFF;
                border: 1px;
                position: relative;
                .scPos{
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 35px;
                    cursor: pointer;
                }
                .isActive{
                    color: #E3954F;
                }
                .tit{
                    display: inline-block;
                    height: 25px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #4A5A81;
                    line-height: 25px;
                    padding-right: 18px;
                }
                .txt{
                    display: inline-block;
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                }
                .jianjie{
                    word-break:break-all;
                    word-wrap : break-word;
                    padding-top:12px;
                    padding-bottom: 24px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                }
            }
        }
    }
    .topic{
      display: flex;
      align-items:center;
    }

</style>